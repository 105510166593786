

.seccion-tabla-interno form.profile-form{
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: var(--ltcolor);
    min-height: fit-content;
    border-radius: .5rem;
}

@media (min-width: 520px) {
    .seccion-tabla-interno form.profile-form{
        background: none;
    }
}

.profile-div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-width: 100%;
    max-width: 1150px;
}
.profile-div hr{
    margin: .8rem 0;
}

.profile-div-part-a,.profile-div-part-b{
    min-width: 35vw !important;
    width: 100%;
    margin: 0 1rem;
}

@media (min-width: 520px) {
    
.profile-div-part-a,.profile-div-part-b{
    width: 40%;
    margin: 0 1rem;
}
}
.profile-form label{
    display: block;
    padding-top: .5rem;
}

.profile-form textarea,
.profile-form input{
    width: 100% !important;
    padding: .5rem;
    border-radius: .5rem;
    border: 1px solid var(--input-border);
}



.seccion-tabla-interno .profile-buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem ;
}

@media (min-width: 750px) {
    .seccion-tabla-interno .profile-buttons{
        flex-direction: row;
    }
}

.seccion-tabla-interno button.profile-button,
.seccion-tabla-interno a.profile-return{
    text-decoration: none;
    display: inline-block;
    text-align: center;
    width: 80%;
    padding: .5rem ;
    font-size: 1rem;
    margin: .5rem;
    border-radius: .5rem;
    color: var(--ltcolor);
}

@media (min-width: 750px) {
    .seccion-tabla-interno button.profile-button,
    .seccion-tabla-interno a.profile-return{
        width: 40%;
    }
}


.seccion-tabla-interno button.profile-button{
    background: var(--redcolor);
}

.seccion-tabla-interno a.profile-return{
    background: var(--thirdcolor);
}