.ticket-update-form{
    width: 100%;
}

.ticket-update-form > div > div >button {
    display: flex;
    margin: 2rem auto 0 auto;
    padding: .7rem;
    width: 90%;
    border: none;
    border-radius: .3rem;
    justify-content: center;
    background: var(--darkcolor);
    color: #fff;
}

form div.ticket-update-priority-topic{
    border-bottom: 1px solid var(--purplecolor);
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    width: 100%;
}

.ticket-update-topic, .ticket-update-priority{
    display: flex;
    flex-direction: column;
    min-width: unset;
    width: 49%;
}

.ticket-update-topic > select,
.ticket-update-priority > select,
.ticket-update-derive-to > div > div > select{
    width: 90%;
    border: none;
    padding: .3rem;
    border-radius: .3rem;
}

.ticket-update-derive-to > div{
    justify-content: space-between;
    display: flex;
}

.ticket-update-derive-to > div > div{
    width: 49%;
}

.ticket_update_body{
    background: var(--purplecolor);
    border-radius: .7rem;
    padding: 1rem;
}