
.seccion-tabla-interno{
min-width: 100%;
}

.seccion-tabla-interno form.ticket-form{
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: var(--ltcolor);
    min-height: fit-content;
    border-radius: .5rem;
}

@media (min-width: 520px) {
    
    .seccion-tabla-interno form.ticket-form{
        background: none;
    }

}

.ticket-create-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-width: 100%;
    max-width: 1150px;
    padding: 1rem 0;
    border-radius: .5rem;
}
.ticket-create-form hr{
    margin: .8rem 0;
}



.ticket-create-part-a,.ticket-create-part-b{
    min-width: 35vw !important;
    width: 100%;
    margin: 0 1rem;
}

@media (min-width: 520px) {
    
.ticket-create-part-a,.ticket-create-part-b{
    width: 40%;
    margin: 0 1rem;
}
}

.ticket-create-form label{
    display: block;
    padding-top: .5rem;
}

.ticket-create-form select,
.ticket-create-form option,
.ticket-create-form textarea,
.ticket-create-form input{
    width: 100% !important;
    padding: .5rem;
    border-radius: .5rem;
    border: none;
    border-bottom: 2px solid var(--purplecolor);    
    background: var(--ltcolor);
    resize: none;
}


.seccion-tabla-interno .ticket-create-buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem ;
}

@media (min-width: 750px) {
    .seccion-tabla-interno .ticket-create-buttons{
        flex-direction: row;
    }
}

.seccion-tabla-interno button.ticket-create-button,
.seccion-tabla-interno button.ticket-create-return{
    text-decoration: none;
    display: inline-block;
    text-align: center;
    width: 80%;
    padding: .5rem ;
    font-size: 1rem;
    margin: .5rem;
    border-radius: .5rem;
    color: var(--ltcolor);
    border: none;

}

@media (min-width: 750px) {
    .seccion-tabla-interno button.ticket-create-button,
    .seccion-tabla-interno button.ticket-create-return{
        width: 40%;
    }
}


.seccion-tabla-interno button.ticket-create-button{
    background: var(--midcolor);
    transition: .3s all linear;
}

.seccion-tabla-interno button.ticket-create-return{
    background: var(--purplecolor);
    transition: .3s all linear;
}

.seccion-tabla-interno button.ticket-create-button:hover,
.seccion-tabla-interno button.ticket-create-return:hover{
    box-shadow: var(--small-shadow);
}


