@import './detalles-update.css';

.seccion-detalle-ticket{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 500px;
    max-width: 80%;
    margin: 0 auto;
}

@media (min-width: 520px) {
    .seccion-detalle-ticket{
        max-width: 100%;
        justify-content: space-between;
    }
}

.seccion-detalle-1{
    flex: 1 1 90%;
    display: flex;
    flex-direction: column;
    min-width: 180px;  
    border-radius: .5rem;
    justify-content: flex-start;
    align-items: center;
    padding-top: 2rem;
    max-width: 90%;
    margin: 2rem auto 0 auto;
}

@media (min-width: 520px) {
    .seccion-detalle-1{
        padding-top: 0;
        max-width: 600px;
    }

    .seccion-detalle-1 h5{
        width: 100%;
        align-self: left;
    }
}

@media (min-width: 1050px) {
    .seccion-detalle-1{
        flex: 0 1 45%;
        align-items: flex-start;
        min-width: 100px;  
        border-radius: .5rem;
        margin:0;
    }
}

@media (min-width: 1450px) {
    .seccion-detalle-1{
        flex: 1 1 20%;
        min-width: 25%;  
        border-radius: .5rem;
        margin:0;
    }
}

.seccion-detalle-2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 1 90%;
    border-radius: .5rem;
    margin: 2rem auto 0 auto;
    
}

@media (min-width: 520px) {
    .seccion-detalle-2{
        min-width: 600px;    
        flex: 0 1 50%;    
        border-radius: .5rem;
    }
}

@media (min-width: 1050px) {
    .seccion-detalle-2{
        margin: 0 1rem;
    }
}

.seccion-detalle-3{
    flex: 1 1 90%;  
    border-radius: .5rem;
    margin: 2rem auto 0 auto;    
}

.seccion-detalle-3 h5{
    text-align: center;
}

@media (min-width: 520px) {
    
    .seccion-detalle-3{
        max-width: 600px;
    }

    .seccion-detalle-3 h5{
        text-align: left;
    }
}

@media (min-width: 1050px) {
    .seccion-detalle-3{
        border-radius: .5rem;
        margin: 0;
    }
}

.seccion-detalle-ticket h4{
    width: 100%;
    color: var(--darkcolor);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    padding: .5rem;
}

@media (min-width: 1050px) {
    .seccion-detalle-1,
    .seccion-detalle-2{
        min-height: 540px;
    }

    .seccion-detalle-3,
    .seccion-detalle-4{
        min-height: 400px;
        max-height: 500px
    }

    .seccion-detalle-ticket h5{
        align-self: flex-start;
    }
}


.seccion-detalle-datos{
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    background: var(--purplecolor);
    border: 1px solid var(--input-border);
    border-radius: .5rem;
    padding-bottom: 1rem;
    max-width: 100%;

}

@media (min-width: 520px) {
    .seccion-detalle-datos{
        justify-content: center;
        min-width: 600px;
    }
}

@media (min-width: 1050px) {
    .seccion-detalle-datos,
    .seccion-detalle-actualizacion{
        min-width: 200px;
    }
}

@media (min-width: 1450px) {
    .seccion-detalle-datos{
        min-width: 95% ;
    }
}

.seccion-detalle-datos > div{
    min-width: 40%;
    margin-right: 1.5rem;
    margin-top: 1rem;
}

@media (min-width: 520px) {
    .seccion-detalle-datos > div{
        margin-left: 1rem;
        min-width: fit-content;
    }
}

@media (min-width: 1050px) {
    .seccion-detalle-datos > div{
        min-width: 90%;
    }
}

@media (min-width: 1450px) {
    .seccion-detalle-datos > div{
        min-width: 40%;
    }
}

@media (min-width: 1050px) {
    
    .seccion-detalle-1{
        flex: 1 1 20%;
    }

    .seccion-detalle-datos > div{
        background: none;
    }
}


.seccion-detalle-datos p {
    margin-bottom: 0;
    margin-left: 0;
    font-size: .8rem !important;
    font-weight: bold;
}

.seccion-detalle-datos span{
    font-size: .8rem;
}

.seccion-detalle-datos-info{
    display: flex;
    flex-wrap: wrap;
}

.seccion-detalle-datos-info > div{
    min-width: 50% !important;
    margin-bottom: 1rem;
}

.seccion-detalle-datos-usuario{
    display: flex;
    width: 100%;
    justify-self: center;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: var(--darkcolor);
    border: none;
    color: #fff;
    padding: .7rem 0;
}

@media (min-width: 520px) {

    .seccion-detalle-datos > div{
        min-width: 90%;
    }
    
    .seccion-detalle-datos-usuario{
        width: 12rem;
        margin: .5rem auto;
        padding: .7rem;
    }

}


@media (min-width: 750px) {
    
    .seccion-detalle-datos-usuario{
        width: 90%;
        margin: .5rem auto;
        padding: .7rem;
    }

}



.seccion-detalle-comentarios{
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    padding: .5rem;
    justify-content: flex-start;
    height: 340px;
    background: var(--fourthcolor);
    border: 1px solid var(--input-border);
    align-items: flex-start;
    overflow-y: auto;
    margin: 0;
    min-width: 200px;
    width:275px;
}

@media (min-width: 520px) {
    .seccion-detalle-comentarios{
        min-width: 600px;
    }
}

@media (min-width: 1450px) {
    .seccion-detalle-comentarios{
        min-width: 95%;
    }
}
.seccion-detalle-comentarios > div{
    margin: 0 .5rem 1rem .5rem;
    border: none;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
}


.seccion-detalle-comentario-usuario,
.seccion-detalle-comentario-usuario-actual{
    width: 90%;
    border-radius: .5rem;
    margin-bottom: .5rem;
}


.seccion-detalle-comentario-usuario-actual{
    align-self: flex-end;
}

.seccion-detalle-comentario-usuario,
.seccion-detalle-comentario-usuario.interno{
    align-self: flex-start  ;
}

.seccion-detalle-comentario-usuario div,
.seccion-detalle-comentario-usuario-actual div{
    background: var(--fourthcolor);
    color: #fff;
    border: 1px solid var(--input-border);
    display: flex;
    padding: .5rem;
    padding-bottom: 0;
}

.seccion-detalle-comentario-usuario div{
    border-top-right-radius: .5rem ;
}
.seccion-detalle-comentario-usuario-actual div{
    border-top-left-radius: .5rem ;
    background: var(--lillycolor);
    color: var(--ltcolor);
}
.seccion-detalle-comentario-usuario.interno div{
    background: var(--thirdcolor) !important;
    color: var(--darkcolor);
    border: 1px solid var(--input-border);
}

.seccion-detalle-comentario-usuario div p,
.seccion-detalle-comentario-usuario-actual div p{
    margin: 0;
    font-size: .8rem !important;
}


.seccion-detalle-comentario-usuario span,
.seccion-detalle-comentario-usuario-actual span{
    margin-left: 5px;
}

.seccion-detalle-comentario-usuario .comentario,
.seccion-detalle-comentario-usuario-actual .comentario{
    padding: .5rem;
    margin-left: 0;
    margin-bottom: 0;
    background: #fff;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    border: 1px solid var(--input-border);
    border-top: none;
    font-size: .8rem !important;
}


.comment-box{
    margin: 1rem;
}

.comment-box-buttons{
    display: flex;
    margin-top: 1rem;
    font-size: .8rem;
    flex-direction: column;
}

@media (min-width: 520px) {

    .comment-box{
        margin: 1rem 0 0 0;
        width: 95%;    
    }
    .comment-box-buttons{
        justify-content: space-between;
        flex-direction: row;
    }
    
}

@media (min-width: 520px) {
    .comment-box-form{
        width: 100%;
    }
}

.comment-box textarea{
    min-width: 275px;
    width: 100%;
    border-radius: .5rem;
    padding: .5rem;
    background: var(--purplecolor);
    border: 1px solid var(--input-border);
    resize: none;
}

@media (min-width: 1450px) {
    .comment-box textarea{
        width: 95%;
    }
    
}


@media (min-width: 520px) {
    input#exampleFormControlFile1.form-control-file{
        font-size: .7rem;
        margin-left: 5rem;
    }
}

.detail-new-tab,
.comment-box button,
td button.release-submit,
td button.take-submit{
    margin: 0 .5rem 0 0;
    padding: .3rem .5rem;
    border-radius: 5px;
    border: none;
    color: var(--ltcolor);
}

.detail-new-tab,
td button.release-submit,
td button.take-submit{
    padding: .4rem .5rem;
    margin: 0  0 0 .3rem;
}

.detail-new-tab{
    background: var(--midcolor);
}

.comment-box .comment-submit{
    background: var(--fourthcolor);
    padding: .3rem 1rem;
}

.comment-box .solved-submit{
    background: var(--thirdcolor);
}

.comment-box .task-submit{
    background: var(--orangecolor2);
}


.comment-box .closed-submit{
    background: var(--redcolor);
}

.comment-box .take-submit,
td button.take-submit{
    background: rgb(255, 206, 45);
}

.comment-box .release-submit,
td button.release-submit{
    background: var(--midcolor);
    color: var(--ltcolor);
    box-shadow: inset 1px 1px 3px 1px black;
}

.comment-box .interno-submit{
    background: var(--fourthcolor);
}
.comment-box .ticket-task-submit{
    background: var(--fourthcolor);
}
.comment-box .file{
    margin: .5rem 0;
}


.comment-box-buttons .exampleFormControlFile1{
    margin-left: 0px !important;
}

.comment-attach-clip{
    color: var(--darkcolor);
    background: var(--fourthcolor);
    padding: .1rem .25rem;
    border-radius: 3px;
    transition: .2s all linear;
    font-weight: 100;
}

.comment-attach-clip:hover{
    color: var(--darkcolor);
    background: var(--midcolor);
}   


.seccion-detalle-interaccion{
    padding: 1rem;
    background: var(--purplecolor);
    border: 1px solid var(--input-border);
    border-radius: .5rem;
    overflow-y: auto;
    margin: 0 auto;
    margin-bottom: 1rem;
    width: 275px;
}

@media (min-width: 520px) {
    .seccion-detalle-interaccion{
        width: 600px;
        max-height: 420px;
    }
    .seccion-detalle-3{
        margin: 1rem 1rem 0 1rem ;
    }
}

.detalle-interaccion{
    background: var(--softcolor);
    padding: .5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-left: 1rem;
    border: 1px solid var(--input-border);
}

.seccion-interaccion{
    border-left: 1px solid var(--darkcolor);
}


.detalle-interaccion-fecha{
    background: var(--softcolor);
    margin-bottom: 1rem;
    border: 1px solid var(--input-border);
    padding: .5rem;
    border-radius: .5rem;
}

.detalle-interaccion-fecha p,
.detalle-interaccion p{
    margin-bottom: 0;
    color: var(--darkcolor);
    font-size: .8rem !important;
}

.detalle-interaccion-fin{
    width: fit-content;
    margin-left: -0.5rem;
}

.detalle-interaccion-fin .detalle-interaccion-fecha{
    background: var(--darkcolor);
}


.seccion-detalle-4{
    flex: 1 1 40%;
    width: 100%;
    margin-top: 1rem;
}

.seccion-detalle-actualizacion{
    background: var(--purplecolor);
    border-radius: .7rem;
    width: 100%;
    max-height: 420px;
}