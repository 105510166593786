
.panel-ticket-secondary-navbar{
    text-decoration: none;
    width: 90%;    
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}

.panel-ticket-secondary-navbar a small{
    text-decoration: none;
    color: var(--ltcolor);
    padding-bottom: 5px;
    margin-bottom: 0;
    padding: 0 1rem 5px 0;
}

.active{
    border-bottom: 3px solid var(--ltcolor);
}

@media (min-width: 520px) {
    
    .panel-ticket-secondary-navbar{
        padding-left: 0;
        margin: auto 1.5rem auto 0;
        padding-top: unset;
        justify-content: right;
    }

    .panel-ticket-secondary-navbar a small,.panel-ticket-secondary-navbar a{
        text-decoration: none !important;
        color: var(--darkcolor);
    }
    .active{
        border-bottom: 3px solid var(--darkcolor);
    }
}


.sec-navbar-active{
    border-bottom: 2px solid var(--darkcolor);
}