
.panel-general{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

@media (min-width: 520px) {
    .panel-general{
        flex-direction: row;
        overflow-x: auto;
        background: var(--midcolor);
    }
}


.panel-general .navbar{
    z-index: 20;
    height: 3.5rem;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    padding: .3rem;
}

@media (min-width: 520px) {
    .panel-general .navbar{
        display: flex;
        position: fixed;
        flex-direction: column;
        height: 100%;
        width: 3.5rem;
        background: var(--darkcolor);
    }
}

.panel-general .navbar img{
    width: 45px;
    padding: 5px;
}

@media (min-width: 520px) {
    .panel-general .navbar img{
        padding: .6rem 0 2rem 0;
    }
}

.navbar{
    display: flex;
}

.navbar-ticket-button,
.navbar-logout-button{
    display: inline;
    font-size: 1.3rem;
    padding: .3rem .8rem;
    margin: auto .3rem;
    color: var(--ltcolor);
    border: 1px solid var(--ltcolor);
    border-radius: .5rem;
    background: none
}

.navbar-menu,
.navbar-user{
    display: flex;
}

@media (min-width: 520px) {
    .navbar-ticket-button,
    .navbar-logout-button{
        margin: unset;
        display: inline-block;
        padding: .3rem .6rem;
        transition: .3s all linear;
    }

    .navbar-ticket-button:hover,
    .navbar-logout-button:hover{
        background: var(--ltcolor);
        color: var(--darkcolor);
    }

    .navbar-menu,
    .navbar-user{
        flex-direction: column;
    }

    .navbar-user{
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
    
    .navbar-user .navbar-ticket-button{
        margin-top: .5rem;
    }

    .navbar-menu .navbar-ticket-button{
        border: 1px solid var(--ltcolor);
        margin-bottom: 1.2rem;
    }

    .navbar-logout-button{
        background: none;
        margin: .2rem 0;
        border: none;
    }

}

/*Tooltip Tooltip Tooltip Tooltip */
.nav-tippy-style{
    background: red;
    padding: 1rem;

}
#nav-tippy-arrow,
#nav-tippy-arrow::before {
    position: absolute;
    width: 1rem;
    height: 1rem;
    z-index: 50;
}

#nav-tippy-arrow::before {
    content: '';
    transform: rotate(45deg);
    background:yellow;
}

.nav-tippy-style[placement='right'] > #nav-tippy-arrow {
    left: 10rem !important;
}

@media (min-width: 520px) {
    .panel-general header{
        margin-left: 3.5rem;
        z-index: 18;
    }
}

.panel-ticket{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: unset;
    color: var(--ltcolor);    
}

.panel-ticket div nav{
    margin: 1rem 2rem 0 1.5rem;
}


@media (min-width: 520px) {
    .panel-general header{
        width: calc(100vw - 3.5rem);
        height: 12rem;
        position: fixed;  
    }
    
    .panel-ticket{
        align-items: flex-start;
        height: 100%;
        color: var(--darkcolor);
        width: 60%;
        justify-content: space-between;
    }
    
}

.panel-general header{
    height: 60px;
    min-width: 70vw;
}

.panel-general > div {
    width: 100%;
}
