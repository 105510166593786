@import './client_ticket_table.css';

.panel_cliente{
    margin: 1.2rem;
    margin-left: 5rem;
}

.panel_cliente_marco_interno{
    display: flex;
    padding: .1rem;
}

.panel_cliente_marco_interno > div{
    margin: 0 1rem;
}

.panel_cliente_izquierda{
    width: 25%;
}

.panel_cliente_derecha{
    width: 74%;
}

.panel_cliente_titulo,
.panel_cliente_tipo_ticket{
    height: 3.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}


/*LADO IZQUIERDO*/
.panel_cliente_titulo > h2{
    font-size: 2.5rem;
    font-weight: bolder;
}

.panel_cliente_filter{
    display: flex;
}

.panel_cliente_filter{
    width: 100%;
    height: 3rem;
}

.panel_cliente_filter > input{
    width: 85%;
    min-height: 2.4rem;
}

.panel_cliente_filter > button{
    min-height: 2.4rem;
    min-width: 15%;
    font-size: 1.2rem;
}

.panel_cliente_crear_ticket > form > div{
    border: 1px solid var(--input-border);
    padding: .7rem;
}

p.panel_cliente_crear_ticket_nuevo{
    margin: 0;
    font-size: 1.2rem !important;
    font-weight: 100;
}
small.panel_cliente_crear_ticket_aclaracion{
    font-size: .9rem !important;
}

small.error_color{
    color:var(--redcolor);
}

.panel_cliente_crear_ticket_inputs{
    padding-top: 1rem;
}

.panel_cliente_crear_ticket_inputs > input{
    margin-bottom: .5rem;
    height: 2rem;
    width: 100%;
    border-radius: .2rem;
    border: 1px solid var(--input-border);
    outline: none;
    padding-left: .5rem;
    transition: .3s all linear;
}

.panel_cliente_crear_ticket_inputs > input:focus{
    border: 1px solid var(--darkcolor_soft);
}

.panel_cliente_crear_ticket_inputs > textarea{
    resize: none;
    width: 100%;
    margin-bottom: .5rem;
    padding-left: .5rem;
}

.panel_cliente datalist{
    display: none !important;
}

.panel_cliente_crear_ticket > form > button{
    display: flex;
    background: var(--thirdcolor);
    border: none;
    border-radius: .3rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.3rem;
    margin: .5rem 0;
}


.panel_cliente_crear_ticket > form > button > small,
.panel_cliente_crear_ticket > form > button > div{
    display: flex;
    font-size: 1.5rem !important;
    align-items: center;
    margin-right: .5rem;
    height: 100%;
    color: #fff;
}

.panel_cliente_crear_ticket > form > button > div{
    font-size: 2rem !important;
}


/*LADO DERECHO*/

.panel_cliente_tipo_ticket > ul{
    display: flex;
    padding-left: 0;
    margin:0 ;
    padding: .7rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--input-border);
    border-radius: .3rem;
}

.panel_cliente_tipo_ticket > ul > a{
    text-decoration: none;
    color: var(--darkcolor);
    list-style: none;
    font-size: 1.2rem;
    padding: .2rem .5rem;
    transition: .3s all linear;
}

.panel_cliente_tipo_activo{
    background: var(--darkcolor_soft);
    border-radius: .3rem;
    color: #fff !important;
}

.panel_cliente_tipo_ticket > ul > a:hover{
    background: var(--darkcolor_soft);
    border-radius: .3rem;
    color: #fff;
}

.rdt_TableHeader{
    display: none !important;
}
